<template>
  <div style="margin: 20px">
    <el-card>
      <el-form :model="form" v-loading="loading">
        <el-form-item label="Идентификатор">
          <el-input v-model="form.identifier"></el-input>
        </el-form-item>
        <el-form-item label="Период">
          <br>
          <el-date-picker
            style="width: 400px"
            v-model="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="dd.MM.yyyy HH:mm:ss"
            type="datetimerange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Ежемесячный ввод">
          <br>
          <el-switch
            v-model="form.is_monthly"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item v-if="form.is_monthly" label="Дата разделения">
          <br>
          <el-date-picker
            style="width: 400px"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="dd.MM.yyyy HH:mm:ss"
            v-model="form.period_separation"
            type="datetime">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save" :disabled="lockButton">{{ isCreate ? 'Создать' : 'Сохранить' }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getPeriod, savePeriod } from '@/api/api'
export default {
  data () {
    return {
      loading: false,
      lockButton: false,
      isCreate: false,
      daterange: [],
      form: {
        id: null,
        identifier: null,
        is_monthly: null,
        period_separation: null,
        date_start: null,
        date_stop: null
      }
    }
  },
  mounted () {
    if (this.$route.name.includes('create')) {
      this.isCreate = true
    } else {
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      getPeriod(this.$route.params.id).then(response => {
        this.form = response.data
        this.daterange = [response.data.date_start, response.data.date_stop]
      }).finally(() => {
        this.loading = false
      })
    },
    save () {
      this.lockButton = true
      this.form.date_start = this.daterange[0]
      this.form.date_stop = this.daterange[1]
      savePeriod(this.form).then((response) => {
        this.$message({
          message: 'Успешно сохранено!',
          type: 'success'
        })
        if (this.isCreate) {
          this.$router.push({ name: this.$route.name.replace('create', 'view'), params: { id: response.data.id } })
        }
      }).finally(() => {
        this.lockButton = false
      })
    }
  }
}
</script>

<style scoped>

</style>
